// components/LandingPage.js
import React from 'react';
import Banner from '../components/Banner.js';
import Header from '../components/Header.js';
import Nav from '../components/Nav.js';
import About from '../components/About.js';
import Services from '../components/Services.js';
import Work from '../components/Work.js';
import Contact from '../components/Contact.js';

const LandingPage = () => {
  return (
    <div className='bg-no-repeat bg-cover overflow-hidden bg'>
      <Header />
      <Banner />
      <Nav />
      <About />
      <Services />
      <Work />
      <Contact />
    </div>
  );
};

export default LandingPage;
