import React, { useState, useEffect } from 'react';
import { BiHomeAlt, BiUser } from 'react-icons/bi';
import { BsClipboardData, BsBriefcase, BsChatSquareText } from 'react-icons/bs';
import Themes from '../components/Themes.js';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../nav.css';

const Nav = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Atualiza o estado isMobile ao redimensionar a janela
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // Função para navegar para a página inicial e rolar até a seção
  const handleNavigation = (section) => {
    navigate('/');
    setShowMenu(false);
  };

  return (
    <nav className='nav flex items-center justify-center'>
      {/* Exibe o Themes apenas no desktop */}
      {!isMobile && <Themes />}

      <div className={`${showMenu ? 'nav_menu show-menu' : 'nav_menu'}`}>
        <div className='nav_list'>
          <div className='nav_item'>
            <ScrollLink
              to='home'
              activeClass='active-nav'
              smooth={true}
              spy={true}
              className='nav_link'
              onClick={() => handleNavigation('home')}
            >
              <BiHomeAlt className='nav_icon' />
              <h3 className='nav_name'>{t('homenav')}</h3>
            </ScrollLink>
          </div>
          <div className='nav_item'>
            <ScrollLink
              to='about'
              activeClass='active-nav'
              smooth={true}
              spy={true}
              className='nav_link'
              onClick={() => handleNavigation('about')}
            >
              <BiUser className='nav_icon' />
              <h3 className='nav_name'>{t('aboutnav')}</h3>
            </ScrollLink>
          </div>
          <div className='nav_item'>
            <ScrollLink
              to='services'
              activeClass='active-nav'
              smooth={true}
              spy={true}
              className='nav_link'
              onClick={() => handleNavigation('services')}
            >
              <BsClipboardData className='nav_icon' />
              <h3 className='nav_name'>{t('servicesnav')}</h3>
            </ScrollLink>
          </div>
          <div className='nav_item'>
            <ScrollLink
              to='plans'
              activeClass='active-nav'
              smooth={true}
              spy={true}
              className='nav_link'
              onClick={() => handleNavigation('work')}
            >
              <BsBriefcase className='nav_icon' />
              <h3 className='nav_name'>{t('productsnav')}</h3>
            </ScrollLink>
          </div>
          <div className='nav_item'>
            <ScrollLink
              to='contact'
              activeClass='active-nav'
              smooth={true}
              spy={true}
              className='nav_link'
              onClick={() => handleNavigation('contact')}
            >
              <BsChatSquareText className='nav_icon' />
              <h3 className='nav_name'>{t('contactnav')}</h3>
            </ScrollLink>
          </div>
          {/* Adicione o Themes como um item de navegação no mobile */}
          {isMobile && (
            <div className='nav_item'>
              <Themes />
            </div>
          )}
        </div>
      </div>

      <div
        className={`${showMenu ? 'nav_toggle animate-toggle' : 'nav_toggle'}`}
        onClick={() => setShowMenu(!showMenu)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  );
};

export default Nav;

