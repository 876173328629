import React from 'react';
import Modal from 'react-modal';
import '../css/ProductDetail.css';
import { fadeIn } from '../variants';
import { useParams } from 'react-router-dom';
import { useProduct } from '../components/ProductContext'; // Ajuste o caminho conforme necessário
import { useTranslation } from 'react-i18next';
import Nav from '../components/Nav';


Modal.setAppElement('#root'); // Definir o elemento raiz para o modal

const ProductDetail = () => {
  const { id } = useParams();
  const { products } = useProduct();
  const product = products.find(p => p.id === parseInt(id));
  const { t } = useTranslation(); // Usar hook de tradução


  if (!product) return <div>{t('product_not_found')}</div>;

  const { title, price, discount, features, description,image ,tag,link } = product;

  return (
    <section className='background-colordetail'>
      <div 
        className='product-detail'
        style={{ position: 'absolute' }}
         // Adicionado para posicionamento absoluto da tag
      >
        {tag && <div className='tag'>{t(tag)}</div>}
        <Nav />
        <div className='product-image'>
          <img src={image} alt={title} />
        </div>
        <div className='product-info'>
          <h2 className='product-title'>{t(title)} {t(price)}</h2>
          <p className='product-discount'>{t(discount)}</p>
          {/* <p className='product-after'>{t(afterDiscount)}</p>
          <p className='product-access'>{t(access)}</p> */}
          <p className='product-description'>{t(description)}</p>
          <ul className='product-features'>
            {features.map((feature, idx) => (
              <li key={idx}>{t(feature)}</li>
            ))}
          </ul>
          {/* <a 
            href='#' 
            className='btn primary-btn mr-2' 
            onClick={() => setModalIsOpen(true)}
          >
            {t('see_demo')}
          </a> */}
          <a href={link} className='btn primary-btn'>{t('subscribe_now')}</a>
        </div>
      </div>

      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel={t('video_demo')}
        className="Modal"
        overlayClassName="Overlay"
      >
        <button className='text-color bold' onClick={() => setModalIsOpen(false)}>{t('close')}</button>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src=""
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal> */}
    </section>
  );
};

export default ProductDetail;
