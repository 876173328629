import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import icon from '../src/assets/logowebbotss.png';
import LandingPage from '../src/pages/LandingPage.js';
import ProductDetails from '../src/pages/ProductDetails.js';

const App = () => {
  const idiomaNavegador = window.navigator.language;
  console.log(idiomaNavegador);

  return (
    <Router>
      <div className='bg-no-repeat bg-cover overflow-hidden bg'>
        <Helmet>
          {/* Icone do site */}
          <link rel="icon" href={icon}></link>
          
          {/* Google Ads tag */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16715505883"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-16715505883');
            `}
          </script>
        </Helmet>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/product_details/:id" element={<ProductDetails />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
