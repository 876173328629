import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const Contact = () => {

  const { t } = useTranslation();
  
  const [showIframe, setShowIframe] = useState(false); // Estado para controlar a exibição do iframe

  return (
    <section className='section' id='contact'>
      <div className='container mx-auto'>

        {/* Botões para WhatsApp e exibir iframe */}
        <div className="button-group" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <button 
            className='btn' 
            onClick={() => window.open('https://wa.me/5585999269030?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20os%20serviços%20de%20automação.', '_blank')}
            style={{ marginRight: '10px',fontSize: 'calc(8px + 1vw)', }}
          >
            {t("Entre em contato conosco no whatsapp")}
          </button>
          <button 
            className='btn'
            style={{ marginRight: '10px',fontSize: 'calc(8px + 1vw)', }}
            onClick={() => setShowIframe(!showIframe)}
          >
            {showIframe ? t("Esconder Chat") : t("Fale com o nosso Assistente")}
          </button>
        </div>

        {/* Exibir o iframe somente quando o botão for clicado */}
        {showIframe && (
          <div className='iframe-container' style={{ marginTop: '0px' }}>
            <iframe
              src="https://bot.webbotss.com.br/groq-ia-com-hist-rico-fr892cg"
              style={{ border: 'none', width: '100%', height: '450px' }}
            ></iframe>
          </div>
        )}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </section>
  );
};

export default Contact;
