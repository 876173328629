import React from 'react';

import CountUp from 'react-countup';

import { useInView } from 'react-intersection-observer';

import { motion } from 'framer-motion';

import { fadeIn } from '../variants'
import {TypeAnimation} from 'react-type-animation';


import { useTranslation } from 'react-i18next';

const About = () => {



  const { t } = useTranslation();

  const {ref, inView} = useInView({
    threshold: 0.5
  });

  const imageUrl = require('../assets/instawhats.png'); 


  return(
    <section className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center
        lg:gap-x-20 lg:gap-y-0 h-screen'>

      <motion.div 
        variants={fadeIn('right', 0.3)}
        initial='hidden'
        whileInView={'show'}
        viewport={{once: false, amount: 0.3}}
        className='image-containerabout'
      >
        <img src={imageUrl} alt="Logo Webbotss" className="responsive-image" /> {/* Substituindo o vídeo pela imagem */}
      </motion.div>

          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{once: false, amount: 0.3}}
            className='flex-1 '
            >
            <h2 className='h3 color lg:text-left text-center'>{t("maxabout")}. </h2>
            <motion.div
              variants={fadeIn('up',0.4)} 
              initial='hidden' whileInView={'show'} 
              viewport={{once:false, amount:0.7}}

              className='h3 mb-4 flex flex-row'
              >
              <span className='text-color mr-4'>{t("Chatbot")}</span>
              <TypeAnimation
              sequence={[
                'IA',
                2000,
                'Instagram',
                2000,
                'Whatsapp',
                2000,
              ]}
              speed={50}
              className='color'
              wrapper='span'
              repeat={Infinity}
              />
            </motion.div> 
            <p className='mb-6 text-color text-sm lg:text-lg font-semibold'>
              {t("description_about")}
            </p>
            <div className='flex gap-x-6 lg:gap-x-10 mb-12 justify-center'>
              <div>
                <div className='text-[40px] font-tertiary color mb-2'>
                  {inView ? <CountUp start={0} end={4} duration={3}/> : null}
                </div>
                <div className='font-primary text-sm text-color tracking-[2px]'>
                {t("yearsof")} <br/>
                {t("experience")}
                </div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary color mb-2'>
                  {inView ? <CountUp start={0} end={80} duration={7}/> : null}
                </div>
                <div className='font-primary text-color text-sm tracking-[2px]'>
                  {t("projects")} <br/>
                  {t("completed")}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>

  ) 
};

export default About;
