import React, { createContext, useState, useContext } from 'react';
import imageWhatsIA from '../assets/chatbotWhatss.png'
const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products] = useState([
    {
      id: 1,
      title: 'Simple Sistem',
      price: "R$ 100",
      discount: 'Special discount for the first month',
      afterDiscount: 'After the first month, the price returns to R$ 100.00',
      access: 'Full access with a 7-day money-back guarantee.',
      description: 'Robô Simples Description',
      image: imageWhatsIA,
      features: [
        'Menu Robot',
        'Highly customizable',
        'Dashboard with data insights',
        'Atendimento 24/7 sem Interrupções',
        "Atendimento com Múltiplos Atendentes", 
        "Gestão de Filas", 
        "Transferência de Clientes", 
        "Agendamento de Mensagens", 
        "Mensagens Rápidas"
      ],
      link : 'https://www.asaas.com/c/brsuv4xl3bwbk5ib'
    },
    {
      id: 2,
      title: 'Complete Version',
      price: "R$ 150.00",
      discount: 'Special discount for the first month',
      afterDiscount: 'After the first month, the price returns to R$ 150',
      access: 'Full access with a 7-day money-back guarantee.',
      description: 'Robô IA Description',
      image: imageWhatsIA,
      features: [
        'Todos os beneficios do sistema simples',
        'Human-like AI customer service',
        'Mass messaging with multimedia',
        'Integração Multicanal',
        'Unlimited sending',
        'Context understanding',
        'AI agent that completes missions',
        'Dashboard with data insights'
      ],
       tag: 'bestseller',
       link : 'https://www.asaas.com/c/5wg9svjp9d4r0gzm'
    }
  ]);

  return (
    <ProductContext.Provider value={{ products }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = () => useContext(ProductContext);
