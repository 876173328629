import React from 'react';

import { BsArrowUpRight} from 'react-icons/bs'

import { motion } from 'framer-motion';

import { fadeIn } from '../variants'

import {services} from '../data/data'

import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

  
const Services = () => {

  const { t } = useTranslation();

  return <section className='section' id='services'>
           <div className='container mx-auto'>
            <div className='flex flex-col lg:flex-row lg:mt-0 mt-60'>

              <motion.div
                variants={fadeIn('right', 0.3)}
                initial='hidden'
                whileInView={'show'}
                viewport={{once: false, amount: 0.3}}
                className='flex-1   bg-contain lg:bg-bottom bg-no-repeat
                  lg:mb-0'
              >
                <h2 className='h3 color lg:mt-0  mt-20 lg:text-left text-center '>{t("what")}.</h2>
                <h3 className=' lg:h3 h4 max-w-[455px] mb-16 text-color'>
                  {t("description_services")}
                </h3>
              </motion.div>
              <motion.div
                variants={fadeIn('left', 0.5)}
                initial='hidden'
                whileInView={'show'}
                viewport={{once: false, amount: 0.3}}
                className='flex-1'
              >

                <div>
                  {services.map((service,index)=>{
                      return (
                            <div 
                                className=' border-b border-color h-auto mb-[20px] flex flex-col pb-1' 
                                key={index} 
                                >
                              <div className='flex flex-row h-12 '>
                                <div className='w-2/3'>
                                  <h4 className='text-[20px] tracking-wider font-primary
                                  font-semibold text-color'>
                                    {t(service.name)}
                                  </h4>
                                </div>
                                <div className='w-1/3 flex justify-end'>
                                  <Link to='work' smooth='true' spy='true'
                                    className='btn w-26 h-10 flex justify-center
                                    items-end'
                                  >
                                    <BsArrowUpRight/>
                                  </Link>
                                </div>
                              </div>
                              <div className='flex flex-1 items-end '>
                                <p className=' font-secondary leading-tight text-color font-semibold w-full'>
                                  {t(service.description)}
                                </p>
                              </div>
                            </div>);
                })}
                </div>
              </motion.div>

            </div>
           </div>
         </section>;
};

export default Services;
