import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from '../src/data/en.json';
import ptTranslations from '../src/data/pt.json';

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false }, // opcional, se você precisar de escape chars
    lng: 'pt', // idioma padrão
    resources: {
      en: {
        translation: enTranslations,
      },
      pt: {
        translation: ptTranslations,
      },
    },
  });

// Função para mudar o idioma e salvar no localStorage
const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  localStorage.setItem('idioma', lng); // Salvar a escolha do usuário no localStorage
};

// Verificar o idioma salvo no localStorage
const idiomaSalvo = localStorage.getItem('idioma');
if (idiomaSalvo) {
  i18n.changeLanguage(idiomaSalvo);
} else {
  // Se não houver idioma salvo, usar o idioma do navegador
  const idiomaNavegador = window.navigator.language;
  if (idiomaNavegador.startsWith('pt')) {
    i18n.changeLanguage('pt');
  } else {
    i18n.changeLanguage('en');
  }
}

export { changeLanguage };
export default i18n;
