import React from 'react';

import Logo from '../assets/logowebbotss.png';
import { useTranslation } from 'react-i18next';
const Header = () => {

  const { t } = useTranslation();
  return (
    <div class="header">
      <img  rel="preload" src={Logo} alt="Logo" class="logo"></img>
      <nav class="navh">
          <ul>
            <li><a href="#home">{t('homenav')}</a></li>
            <li><a href="https://app.webbotss.com.br/login">{t('login')}</a></li>
          </ul>
      </nav>
    </div>
  )
  
};

export default Header;
