import React from 'react';
import '../css/Work.css';
import PricingCard from './PricingCard';
import { useTranslation } from 'react-i18next';
import { useProduct } from '../components/ProductContext'; // Importe o contexto de produtos

const Work = () => {
  const { t } = useTranslation();
  const { products } = useProduct(); // Acesse os produtos do contexto

  return (
    <section className='section' id='plans'>
      <div className='container mx-auto'>
        <div className='pricing-container'>
          {products.map((product, index) => (
            <PricingCard
              key={product.id}
              index={index}
              id={product.id}
              title={t(product.title)} // Traduzir o título
              price={product.price}
              discount={t(product.discount)} // Traduzir o desconto
              afterDiscount={t(product.afterDiscount)} // Traduzir o texto após o desconto
              access={t(product.access)} // Traduzir o acesso
              features={product.features.map(feature => t(feature))} // Traduzir as features
              image={product.image}
              tag={product.tag ? t(product.tag) : undefined} // Traduzir a tag se existir
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Work;
